















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import MasterDepositoryModule from '@/store/modules/master/depository'
import { LargeKnowledgeResource } from '@/store/types'
// utils
import { downloadOriginalFile } from '@/utils/services/config'

@Component({
  components: {
    AttachmentsWrapper,
  },
})
export default class DepositoryFilesFolder extends Mixins(NotifyMixin, PermissionsMixin) {
  private folder: LargeKnowledgeResource | null = null

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get folderID () {
    return +this.$route.params.folderID
  }

  private get monthID () {
    return +this.$route.params.monthID
  }

  private mounted () {
    MasterDepositoryModule.fetchTrainingFilesFolder({ courseID: this.courseID, courseFolderID: this.monthID, mediaFolderID: this.folderID })
      .then(response => {
        this.folder = response
      })
      .catch(err => {
        if (err && err.response && err.response.status !== 404) {
          this.notifyError(err)
        }
      })
  }

  private handleDownload() {
    MasterDepositoryModule.downloadTrainingFilesFolder({
      courseID: this.courseID,
      courseFolderId: this.monthID,
      mediaFolderId: this.folderID,
    })
      .then(response => {
        window.open(`${downloadOriginalFile}${response.id}`, '_blank')
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.folder ? this.folder.folder : 'Папка',
    }
  }
}
